<template>
  <footer>
    <div class="footer">
      <div class="top-bar"></div>
      <div class="container d-flex flex-wrap justify-content-center justify-content-md-end py-5 px-0 align-items-center">
        <a data-aos="fade-up" class="col-12 col-md-2 mb-4 text-center">
          <img src="../assets/logo-agec.svg" alt="logo AGEC" class="mr-4" />
        </a>
        <div data-aos="fade-up" class="col-12 col-md-3 footer__text text-center text-md-left">
            <h6>Secretaria de Cultura </h6>
            <h6>y Recreación AGEC</h6>
        </div>
        <div
          data-aos="fade-up" class="col-12 col-md-3 text-center text-md-left"
        >
          <ul class="list">
            <li>
                <font-awesome-icon icon="phone"></font-awesome-icon>
                Dean Funes 244 - Córdoba</li>
            <li><font-awesome-icon icon="map-marker-alt"></font-awesome-icon>
                0351 - 4236418, int. 130</li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss">
@import "../scss/styles.scss";

.footer {
  width: 100%;
  background: #333131;
  &__text{
      h6{
          color: white;
          font-family: 'Open Sans', sans-serif;
          font-weight: 600;
          font-size: 16px;
      }
  }
  .list{
      list-style-type: none;
      padding-left: 0;
      li{
          color: white;
          font-family: 'Open Sans', sans-serif;
          font-weight: 400;
          font-size: 14px;
          margin: 10px 0 10px 0;
      }
  }
  .top-bar {
    width: 100%;
    height: 12px;
    background-color: $primaryColor;
  }
}
@media (min-width: 768px) {
.footer {
  .list{
      padding-left: 30px;
      list-style-type: none;
      border-left: 1px solid white;
  }
}
}
</style>
