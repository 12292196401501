<template>
  <div class="container content px-0">
    <div class="content__type">
      <h1><strong>Cursos </strong>disponibles</h1>
    </div>
    <div class="col-12 ">
       <img src="../assets/cursos.png" class="w-100" alt="cursos" />
    </div>
    <!-- <div class="card-group">
      <div class="card col-12 col-md-4 px-0 mx-0 mx-md-3" v-for="item in cursos" :key="item._id">
        <img :src="item.Foto.url" class="card-img-top" alt="..." />
        <div data-aos="fade-up" class="card-body">
          <h5 class="card-title pt-3">{{ item.Nombre }}</h5>
          <p class="card-text">{{ item.Contenido }}</p>
          <small class="card-category" :class="item.curso_categoria.slug">{{ item.curso_categoria.Categoria }}</small>
        </div>
      </div>
    </div> -->
    <div class="col-12 text-center py-3">
      <!-- <b-alert show variant="danger">Las inscripciones se habilitaran a partir del 29/09/21</b-alert> -->
      <!-- <a
        href="https://docs.google.com/forms/d/e/1FAIpQLScoOZkn-2cdjIyZQ38cb44gVZpWHI7ugfY1GMe_9LI021JgQQ/viewform?usp=sf_link"
        target="_blank"
        class="btn btn-primary btn-large"
        >Inscribirse</a
      > -->
    </div>
    <div class="col-12 text-center content__info pt-3">
      <h2 class="text-danger text-bold">INSCRIPCIONES ABIERTAS</h2>
      <p>La inscripción a los cursos virtuales se realizará unicamente de forma PRESENCIAL en la sede de la secretaria de Cultura AGEC, en calle Dean Funes 244, de Lunes a Viernes de 08 a 21hs a partir del 08/02, para mayor información comunicarse al:</p>
      <p>0351 - 4236418 int. 130</p>
    </div>
    <div class="content__type">
      <h1><strong>Preguntas </strong>frecuentes</h1>
    </div>
    <div class="col-12 pt-4 px-3 px-md-5">
      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-1 variant="info">¿Quiénes pueden acceder a los cursos de Campus Virtual AGEC?</b-button>
          </b-card-header>
          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>Socios titulares de la AGEC y su grupo familiar.</b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="info">¿Los cursos tienen algún costo?</b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>NO</b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-3 variant="info">¿Los cursos son presenciales?</b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>NO, los cursos son 100% online, con materiales teóricos y clases en vivo a través de la plataforma.</b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-4 variant="info">¿Cómo me inscribo a un curso?</b-button>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Deberás hacer click en el botón "Inscribirse" en la página principal y completar el formulario. También podás hacer click aquí o ver
                el siguiente video:
                <div class="col-12 col-md-6 m-auto px-0">
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/6UQfOvZZ1yE"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-5 variant="info">¿Cómo accedo una vez inscripto?</b-button>
          </b-card-header>
          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Si tus datos son correctos, te enviaremos un e-mail con tu Usuario y Contraseña 48 hs. hábiles antes de la fecha de inicio del curso.
                Anota tus datos y seguí estos pasos para acceder:
                <div class="col-12 col-md-6 m-auto px-0">
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/6rKWiTK1M2o"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-6 variant="info">¿Cómo entrego una tarea?</b-button>
          </b-card-header>
          <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <div class="col-12 col-md-6 m-auto px-0">
                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/Rnu3_zHFgU0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-7 variant="info">¿Entregan certificados?</b-button>
          </b-card-header>
          <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text
                >Si, lo recibirás por e-mail una vez finalizado el curso, siempre que hayas entregado las actividades previstas y aprobado el examen
                final.</b-card-text
              >
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <!-- <div v-if="loading" class="content__loading">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  data() {
    return {
      cursos: "",
      loading: true,
    };
  },
  methods: {
    async getCourses() {
      let datos = await axios.get("https://agec-strapi.herokuapp.com/cursos");
      console.log(datos.data);
      this.cursos = datos.data;
      this.loading = false;
    },
  },
  created() {
    this.getCourses();
  },
};
</script>

<style lang="scss">
@import "../scss/styles.scss";
.content {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 30px 0;

  &__type {
    text-align: center;
    width: 100%;
    border-bottom: 6px solid $agecColor;
    padding: 0.5rem 1rem;
    h1 {
      font-family: $primary-font;
      font-size: 22px;
      line-height: 38px;
      margin-bottom: 0;
      b {
        font-weight: bold;
      }
    }
  }
  .card-group {
    display: flex;
    flex-wrap: wrap;
    padding: 3rem;
    // justify-content: center;
    min-height: 400px;
    position: relative;
    .card {
      margin-bottom: 1rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      img {
        width: 100%;
      }
      &-body {
        position: relative;
      }
      &-title {
        font-family: $primary-font;
        font-weight: 700;
        font-size: 18px;
      }
      &-text {
        font-family: $primary-font;
        font-weight: 400;
        font-size: 14px;
        line-height: 2;
        color: #7a7a7a;
        white-space: pre-wrap;
      }
      &-category {
        position: absolute;
        left: 0;
        top: -15px;
        color: white;
        font-family: $primary-font;
        font-weight: 700;
        font-size: 14px;
        padding: 0.2rem 1rem;
        border-radius: 0 15px 15px 0;
        &.proximamente {
          background-color: #5bd2a0;
        }
        &.inscripcion-abierta {
          background-color: $primaryColor;
        }
        &.sin-cupos {
          background-color: #cf2008;
        }
      }
    }
  }
  .btn {
    &-large {
      font-size: 28px;
      font-family: $primary-font;
      font-weight: 600;
      padding: 0.5rem 2.5rem;
    }
  }
  &__info {
    p {
      font-family: $primary-font;

      &:first-child {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
      }
      &:last-child {
        font-size: 28px;
        font-weight: 700;
      }
    }
  }
  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .spinner-border {
      color: $primaryColor;
      width: 4rem;
      height: 4rem;
    }
  }
}
.accordion {
  .card {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
    background-color: transparent !important;
    .card-header {
      border: none !important;
      background-color: transparent !important;
      .btn.btn-info {
        background-color: transparent !important;
        border: none !important;
        color: black;
        font-weight: 600;
        padding: 1rem 1rem;
        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }
    .card-text {
      text-align: center;
    }
  }
}

@media (min-width: 576px) {
  .card {
    min-width: 230px !important;
  }
}
@media (min-width: 768px) {
  .content {
    &__type {
      text-align: left;
    }
  }
  // .card {
  //   margin-right: .5rem;
  //   margin-left: .5rem;

  // }
}
@media (min-width: 992px) {
  // .card {
  //   margin-right: 1.5rem;
  //   margin-left: 1.5rem;
  // }
}
</style>
