<template>
  <header>
    <div class="header">
      <div
        class="container d-flex flex-wrap justify-content-center justify-content-md-between py-4 px-0"
      >
        <a data-aos="fade-up" class="logo-block col-12 col-md-4 mb-4 mb-md-0">
          <img
            src="../assets/logo-agec-campus.svg"
            alt="logo AGEC"
            class="mr-4"
          />
        </a>
        <div
          data-aos="fade-up"
          class="d-flex flex-column justify-content-between "
        >
          <a href="https://campusvirtualagec.com/moodle/login/index.php" class="btn btn-sm btn-primary">ACCEDER</a>
        </div>
      </div>
      <div class="bottom-bar"></div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="scss">
@import "../scss/styles.scss";


.header {
  background-color: $agecColor;
  width: 100%;
  min-height: 120px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  .logo-block {
      text-align: center;
    img {
      transition: all 0.3s ease-out;
      max-width: 280px;
      
    }
    &:hover {
      cursor: pointer;
      img {
        opacity: 0.9;
      }
    }
  }
  .btn {
    &.btn-sm {
      padding: 0.25rem 1.4rem;
    }
  }
  .bottom-bar {
    width: 100%;
    height: 12px;
    background-color: $primaryColor;
  }
}
@media (min-width: 768px) {
  .header {
    .logo-block {
        text-align: left;
    }
  }
}
</style>
